import { useMutation, useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import { LiveTicker } from '../../../../../BettingApp/LiveTicker';
import { systemSetPopupMessage } from '../../../../../System/actions';
import { getTournamentApi2, getTournamentSysAdminApi } from '../../../../../System/systemConfig';
import { ButtonWithConfirmation } from '../../../../../common/ButtonWithConfirmation';
import { ExternalKeyViewAndEdit } from '../../../../ExternalKeyViewAndEdit';
import { GameResultEditComponent } from '../../GameResultEdit';

interface GameEditProps {
    trId: string;
    gameId?: string;
    isShown: boolean;
    onClose: () => void;
}

export const GameEditComp: FC<GameEditProps> = (props) => {
    const [availableGameStates, setAvailableGameStates] = useState<string[]>([]);
    const [showAllTeams, setShowAllTeams] = useState(false);

    const [trId, setTrId] = useState(props.trId);
    const [teamId1, setTeamId1] = useState('');
    const [teamId2, setTeamId2] = useState('');
    const [infoText, setInfoText] = useState('');
    const [gameState, setGameState] = useState('');
    const [finished, setFinished] = useState(false);
    const [intermediateResult, setIntermediateResult] = useState(false);
    const [bettingAllowed, setBettingAllowed] = useState(false);
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [timeZone, setTimeZone] = useState('Europe/Berlin');

    const [showExternalKeys, setShowExternalKeys] = useState(false);
    const [showLiveTicker, setShowLiveTicker] = useState(false);

    const availableTimeZones = moment.tz.names();

    const dispatch = useDispatch();

    // get the current time zone
    useEffect(() => {
        setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }, []);

    const [changeTournamentRoundEnabled, setChangeTournamentRoundEnabled] = useState(false);

    const api = getTournamentSysAdminApi();
    const tournamentApi = getTournamentApi2();

    useEffect(() => {
        setChangeTournamentRoundEnabled(false);
    }, []);

    const availableTeamsQuery = useQuery({
        queryKey: ['teams.getAvailableForGame', props.trId, props.gameId!],
        queryFn: () => api.getAvailableTeamsForGame({ trId: props.trId, gameId: props.gameId!, all: showAllTeams }),
        refetchOnWindowFocus: false,
        enabled: props.isShown && props.gameId !== undefined && props.gameId !== null,
    });

    const tournamentRoundQuery = useQuery({
        queryKey: ['tournamentRound.getById', props.trId],
        queryFn: () => tournamentApi.getTournamentRoundById({ trId: Number(props.trId) }),
        refetchOnWindowFocus: false,
        enabled: props.isShown,
    });

    const availableTournamentRoundQuery = useQuery({
        queryKey: ['tournamentRounds.byTournamentAndType', props.trId, 'betting'],
        queryFn: () =>
            tournamentApi.getTournamentRoundsByType({
                tournamentId: tournamentRoundQuery.data?.tournamentId!,
                type: 'betting',
            }),
        refetchOnWindowFocus: false,
        enabled: tournamentRoundQuery.data?.tournamentId !== undefined && props.isShown,
    });

    const availableGamesQuery = useQuery({
        queryKey: ['getAvailableGameStates'],
        queryFn: () => api.getAvailableGameStates(),
        refetchOnWindowFocus: false,
        enabled: props.isShown,
    });

    useEffect(() => {
        if (availableGamesQuery.data) {
            setAvailableGameStates(availableGamesQuery.data);
        }
    }, [availableGamesQuery.data]);

    const setLoadAllTeams = (v: boolean) => {
        setShowAllTeams(v);
    };

    useEffect(() => {
        availableTeamsQuery.refetch();
    }, [availableTeamsQuery, showAllTeams]);

    const gameQuery = useQuery({
        queryKey: ['teams.getGameSysAdminById', props.gameId],
        queryFn: () => api.getGameSysAdmin({ gameId: props.gameId! }),
        enabled: !!props.gameId && props.isShown,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (gameQuery.data) {
            setTeamId1(gameQuery.data.team1Id);
            setTeamId2(gameQuery.data.team2Id);
            setTrId(gameQuery.data.tournamentRoundId);
            setInfoText(gameQuery.data.text ?? '');
            setGameState(gameQuery.data.state);
            setBettingAllowed(gameQuery.data.bettingAllowed);
            setFinished(gameQuery.data.finished);
            setIntermediateResult(gameQuery.data.intermediateResult);
            setDate(moment(gameQuery.data.date).format('YYYY-MM-DD') ?? '');
            setTime(moment(gameQuery.data.date).format('HH:mm') ?? '');
            setTimeZone(DateTime.fromJSDate(gameQuery.data.date).zoneName ?? '');
        } else {
            setTeamId1('');
            setTeamId2('');
            setTrId(props.trId);
            setInfoText('');
            setGameState('');
            setBettingAllowed(false);
            setFinished(false);
            setIntermediateResult(false);
            setDate('');
            setTime('');
            setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        }
    }, [gameQuery.data, props.trId]);

    const updateGameMutation = useMutation({
        mutationFn: () =>
            api.updateGame({
                gameId: props.gameId!,
                team1Id: teamId1,
                team2Id: teamId2,
                dateStr: date,
                timeStr: time,
                timeZone,
                state: gameState,
                trId,
                infoText,
                bettingAllowed,
                intermediateResult,
                finished,
            }),
        onSuccess() {
            dispatch(systemSetPopupMessage('Game updated successfully'));
        },
        onError(error, variables, context) {
            dispatch(systemSetPopupMessage('Error updating game: ' + (error as any)?.response?.status));
        },
    });

    const addGameMutation = useMutation({
        mutationFn: () =>
            api.createGame({
                team1Id: teamId1,
                team2Id: teamId2,
                dateStr: date,
                timeStr: time,
                timeZone,
                state: gameState,
                trId,
                infoText,
                bettingAllowed,
                intermediateResult,
                finished,
            }),
        onSuccess() {
            dispatch(systemSetPopupMessage('Game created successfully'));
        },
        onError(error, variables, context) {
            dispatch(systemSetPopupMessage('Error creating game: ' + JSON.stringify(error)));
        },
    });

    const enableChangeTournamentRound = () => {
        setChangeTournamentRoundEnabled(true);
    };

    const onCancel = () => {
        props.onClose();
    };
    const onSave = () => {
        if (verifyGame()) {
            updateGameMutation.mutateAsync().then(() => props.onClose());
        }
    };

    const onAdd = () => {
        if (verifyGame()) {
            addGameMutation.mutateAsync().then(() => props.onClose());
        }
    };

    const isStateValid = (): boolean => {
        return !!availableGameStates && availableGameStates.includes(gameState);
    };

    const isTeam1Valid = (): boolean => {
        return !!teamId1 && !!availableTeamsQuery?.data && availableTeamsQuery.data.some((t) => t.id === teamId1);
    };

    const isTeam2Valid = (): boolean => {
        return (
            !!teamId2 &&
            !!availableTeamsQuery?.data &&
            availableTeamsQuery.data.some((t) => t.id === teamId2) &&
            teamId1 !== teamId2
        );
    };

    const verifyGame = (): boolean => {
        return isStateValid() && isTeam1Valid() && isTeam2Valid();
    };

    const onShowExternalKeys = () => {
        setShowExternalKeys(true);
    };

    const onShowLiveTicker = () => {
        setShowLiveTicker(true);
    };

    return (
        <>
            <Modal isOpen={props.isShown} toggle={onCancel} size="lg">
                <ModalHeader>{props.gameId ? 'Edit' : 'Add'} Game</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row className="align-items-center my-2">
                            <Col>
                                <Label>
                                    <Input
                                        className="mx-2"
                                        type="checkbox"
                                        checked={showAllTeams}
                                        onChange={(e) => setLoadAllTeams(e.currentTarget.checked)}
                                    />
                                    Show all Teams
                                </Label>{' '}
                            </Col>
                            <Col>
                                {!showExternalKeys && (
                                    <Button color="link" onClick={onShowExternalKeys}>
                                        External Keys
                                    </Button>
                                )}
                            </Col>
                            <Col>
                                {!showLiveTicker && (
                                    <Button color="link" onClick={onShowLiveTicker}>
                                        Live-Ticker
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        {showExternalKeys && (
                            <Row>
                                <Col>
                                    <ExternalKeyViewAndEdit
                                        entityType="game"
                                        entityId={props.gameId!}
                                        onClose={() => setShowExternalKeys(false)}
                                    />
                                </Col>
                            </Row>
                        )}
                        {showLiveTicker && (
                            <Row>
                                <Col>
                                    <LiveTicker gameId={props.gameId!} onClose={() => setShowLiveTicker(false)} />
                                </Col>
                            </Row>
                        )}

                        <Row className="align-items-center my-2">
                            <Col>
                                <Input
                                    type="select"
                                    invalid={!isTeam1Valid()}
                                    value={teamId1}
                                    onChange={(e) => setTeamId1(e.currentTarget.value)}
                                >
                                    {availableTeamsQuery?.data?.map((t) => (
                                        <option key={t.id} value={t.id}>
                                            {t.name}
                                        </option>
                                    ))}
                                    <option value="">None</option>
                                    <FormFeedback>Illegal team</FormFeedback>
                                </Input>
                            </Col>
                            <Col>
                                <Input
                                    type="select"
                                    invalid={!isTeam2Valid()}
                                    value={teamId2}
                                    onChange={(e) => setTeamId2(e.currentTarget.value)}
                                >
                                    {availableTeamsQuery?.data?.map((t) => (
                                        <option key={t.id} value={t.id}>
                                            {t.name}
                                        </option>
                                    ))}
                                    <option value="">None</option>
                                    <FormFeedback>Illegal team</FormFeedback>{' '}
                                </Input>
                            </Col>
                        </Row>

                        <Row className="align-items-center my-2">
                            <Col className="">
                                <Label className="m-0">Date & Time</Label>
                            </Col>
                            <Col className="">
                                <Input type="date" value={date} onChange={(e) => setDate(e.currentTarget.value)} />
                            </Col>
                            <Col className="">
                                <Input type="time" value={time} onChange={(e) => setTime(e.currentTarget.value)} />
                            </Col>
                        </Row>
                        <Row className="align-items-center my-2">
                            <Col className="">
                                <Label className="m-0">Time Zone</Label>
                            </Col>
                            <Col className="">
                                <Input
                                    type="select"
                                    value={timeZone}
                                    onChange={(e) => setTimeZone(e.currentTarget.value)}
                                >
                                    {availableTimeZones.map((tz) => (
                                        <option key={tz} value={tz}>
                                            {tz}
                                        </option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <Row className="align-items-center my-2">
                            <Col className="col">
                                <Label className="m-0">State</Label>
                            </Col>
                            <Col className="col">
                                <Input
                                    type="select"
                                    invalid={!isStateValid()}
                                    value={gameState}
                                    onChange={(e) => setGameState(e.currentTarget.value)}
                                >
                                    {availableGameStates.map((gs) => (
                                        <option key={gs} value={gs}>
                                            {gs}
                                        </option>
                                    ))}
                                    <option value="">None</option>
                                </Input>
                                <FormFeedback>Illegal state</FormFeedback>
                            </Col>
                        </Row>
                        <Row className="align-items-center my-2">
                            <Col className="align-middle text-nowrap">
                                <Label className="m-0">Tournament Round</Label>
                                {!changeTournamentRoundEnabled && (
                                    <ButtonWithConfirmation
                                        color="link"
                                        onOK={enableChangeTournamentRound}
                                        title="Change Tournament Round"
                                        message="Are you sure you want to change the Tournament Round of the game?"
                                    >
                                        change
                                    </ButtonWithConfirmation>
                                )}
                            </Col>
                            <Col>
                                <Input
                                    type="select"
                                    value={trId}
                                    onChange={(e) => setTrId(e.currentTarget.value)}
                                    disabled={!changeTournamentRoundEnabled}
                                >
                                    {availableTournamentRoundQuery.data &&
                                        availableTournamentRoundQuery.data.map((tr) => (
                                            <option key={tr.id} value={tr.id}>
                                                {tr.longName}
                                            </option>
                                        ))}
                                </Input>
                            </Col>
                        </Row>
                        <Row className="align-items-center my-2">
                            <Col className="col-2 align-middle">
                                <Label className="m-0">Info text</Label>
                            </Col>
                            <Col>
                                <Input value={infoText} onChange={(e) => setInfoText(e.currentTarget.value)} />
                            </Col>
                        </Row>
                        <Row className="align-items-center my-2">
                            <Col className="text-center">
                                <Label>
                                    <Input
                                        className="mx-2"
                                        type="checkbox"
                                        checked={bettingAllowed}
                                        onChange={(e) => setBettingAllowed(e.currentTarget.checked)}
                                    />
                                    Betting allowed
                                </Label>
                            </Col>
                            <Col className="text-center">
                                <Label>
                                    <Input
                                        className="mx-2"
                                        type="checkbox"
                                        checked={intermediateResult}
                                        onChange={(e) => setIntermediateResult(e.currentTarget.checked)}
                                    />
                                    Intermediate Result
                                </Label>
                            </Col>
                            <Col className="text-center">
                                <Label>
                                    <Input
                                        className="mx-2"
                                        type="checkbox"
                                        checked={finished}
                                        onChange={(e) => setFinished(e.currentTarget.checked)}
                                    ></Input>
                                    Finished?
                                </Label>
                            </Col>
                        </Row>
                    </Form>
                    {props.gameId && <GameResultEditComponent gameId={props.gameId} />}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={onCancel}>
                        Cancel
                    </Button>
                    {props.gameId && (
                        <Button color="primary" onClick={onSave}>
                            Save
                        </Button>
                    )}
                    {!props.gameId && (
                        <Button color="primary" onClick={onAdd}>
                            Add Game
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
};
