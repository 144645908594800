/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RssFeedImage
 */
export interface RssFeedImage {
    /**
     * 
     * @type {string}
     * @memberof RssFeedImage
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof RssFeedImage
     */
    altText?: string;
    /**
     * 
     * @type {string}
     * @memberof RssFeedImage
     */
    width?: string;
    /**
     * 
     * @type {string}
     * @memberof RssFeedImage
     */
    height?: string;
}

/**
 * Check if a given object implements the RssFeedImage interface.
 */
export function instanceOfRssFeedImage(value: object): value is RssFeedImage {
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function RssFeedImageFromJSON(json: any): RssFeedImage {
    return RssFeedImageFromJSONTyped(json, false);
}

export function RssFeedImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): RssFeedImage {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'],
        'altText': json['altText'] == null ? undefined : json['altText'],
        'width': json['width'] == null ? undefined : json['width'],
        'height': json['height'] == null ? undefined : json['height'],
    };
}

export function RssFeedImageToJSON(value?: RssFeedImage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'url': value['url'],
        'altText': value['altText'],
        'width': value['width'],
        'height': value['height'],
    };
}

