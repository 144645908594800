/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamDetail
 */
export interface TeamDetail {
    /**
     * 
     * @type {string}
     * @memberof TeamDetail
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDetail
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDetail
     */
    stadium?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamDetail
     */
    stadiumCapacity?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamDetail
     */
    established?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDetail
     */
    imageId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDetail
     */
    stadiumImageId?: string;
}

/**
 * Check if a given object implements the TeamDetail interface.
 */
export function instanceOfTeamDetail(value: object): value is TeamDetail {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function TeamDetailFromJSON(json: any): TeamDetail {
    return TeamDetailFromJSONTyped(json, false);
}

export function TeamDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'stadium': json['stadium'] == null ? undefined : json['stadium'],
        'stadiumCapacity': json['stadiumCapacity'] == null ? undefined : json['stadiumCapacity'],
        'established': json['established'] == null ? undefined : json['established'],
        'imageId': json['imageId'] == null ? undefined : json['imageId'],
        'stadiumImageId': json['stadiumImageId'] == null ? undefined : json['stadiumImageId'],
    };
}

export function TeamDetailToJSON(value?: TeamDetail | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'stadium': value['stadium'],
        'stadiumCapacity': value['stadiumCapacity'],
        'established': value['established'],
        'imageId': value['imageId'],
        'stadiumImageId': value['stadiumImageId'],
    };
}

