/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GameEvent
 */
export interface GameEvent {
    /**
     * 
     * @type {string}
     * @memberof GameEvent
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GameEvent
     */
    type: number;
    /**
     * 
     * @type {number}
     * @memberof GameEvent
     */
    minute: number;
    /**
     * 
     * @type {number}
     * @memberof GameEvent
     */
    extraMinute: number;
    /**
     * 
     * @type {number}
     * @memberof GameEvent
     */
    result1: number;
    /**
     * 
     * @type {number}
     * @memberof GameEvent
     */
    result2: number;
    /**
     * 
     * @type {string}
     * @memberof GameEvent
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof GameEvent
     */
    team?: string;
    /**
     * 
     * @type {string}
     * @memberof GameEvent
     */
    playerId1?: string;
    /**
     * 
     * @type {string}
     * @memberof GameEvent
     */
    playerId1FirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof GameEvent
     */
    playerId1LastName?: string;
    /**
     * 
     * @type {string}
     * @memberof GameEvent
     */
    playerId2?: string;
    /**
     * 
     * @type {string}
     * @memberof GameEvent
     */
    playerId2FirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof GameEvent
     */
    playerId2LastName?: string;
}

/**
 * Check if a given object implements the GameEvent interface.
 */
export function instanceOfGameEvent(value: object): value is GameEvent {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('minute' in value) || value['minute'] === undefined) return false;
    if (!('extraMinute' in value) || value['extraMinute'] === undefined) return false;
    if (!('result1' in value) || value['result1'] === undefined) return false;
    if (!('result2' in value) || value['result2'] === undefined) return false;
    return true;
}

export function GameEventFromJSON(json: any): GameEvent {
    return GameEventFromJSONTyped(json, false);
}

export function GameEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'minute': json['minute'],
        'extraMinute': json['extraMinute'],
        'result1': json['result1'],
        'result2': json['result2'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'team': json['team'] == null ? undefined : json['team'],
        'playerId1': json['playerId1'] == null ? undefined : json['playerId1'],
        'playerId1FirstName': json['playerId1FirstName'] == null ? undefined : json['playerId1FirstName'],
        'playerId1LastName': json['playerId1LastName'] == null ? undefined : json['playerId1LastName'],
        'playerId2': json['playerId2'] == null ? undefined : json['playerId2'],
        'playerId2FirstName': json['playerId2FirstName'] == null ? undefined : json['playerId2FirstName'],
        'playerId2LastName': json['playerId2LastName'] == null ? undefined : json['playerId2LastName'],
    };
}

export function GameEventToJSON(value?: GameEvent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'minute': value['minute'],
        'extraMinute': value['extraMinute'],
        'result1': value['result1'],
        'result2': value['result2'],
        'comment': value['comment'],
        'team': value['team'],
        'playerId1': value['playerId1'],
        'playerId1FirstName': value['playerId1FirstName'],
        'playerId1LastName': value['playerId1LastName'],
        'playerId2': value['playerId2'],
        'playerId2FirstName': value['playerId2FirstName'],
        'playerId2LastName': value['playerId2LastName'],
    };
}

