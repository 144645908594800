/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BestBet,
  Bet,
  BetQuote,
  GameBet,
  GameBetInfo,
  RestResult,
} from '../models/index';
import {
    BestBetFromJSON,
    BestBetToJSON,
    BetFromJSON,
    BetToJSON,
    BetQuoteFromJSON,
    BetQuoteToJSON,
    GameBetFromJSON,
    GameBetToJSON,
    GameBetInfoFromJSON,
    GameBetInfoToJSON,
    RestResultFromJSON,
    RestResultToJSON,
} from '../models/index';

export interface GetBestGameBetsRequest {
    clientId: number;
    gameId: number;
}

export interface GetBetQuoteRequest {
    bet1: number;
    bet2: number;
    gameId: number;
    tenant: string;
    ugId?: number;
}

export interface GetGameBetInfoRequest {
    gameId: number;
    tenant: string;
}

export interface GetGameBetsRequest {
    trId: number;
}

export interface GetPublicGameBetsRequest {
    trId: number;
    userId: string;
}

export interface MergeBetsRequest {
    destUserId: number;
    srcUserName: string;
}

export interface SaveGameBetsRequest {
    bet?: Bet;
}

/**
 * 
 */
export class BettingControllerApi extends runtime.BaseAPI {

    /**
     */
    async getBestGameBetsRaw(requestParameters: GetBestGameBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BestBet>>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling getBestGameBets().'
            );
        }

        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling getBestGameBets().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bets/{gameId}/{clientId}/best-bets`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))).replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BestBetFromJSON));
    }

    /**
     */
    async getBestGameBets(requestParameters: GetBestGameBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BestBet>> {
        const response = await this.getBestGameBetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBetQuoteRaw(requestParameters: GetBetQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BetQuote>> {
        if (requestParameters['bet1'] == null) {
            throw new runtime.RequiredError(
                'bet1',
                'Required parameter "bet1" was null or undefined when calling getBetQuote().'
            );
        }

        if (requestParameters['bet2'] == null) {
            throw new runtime.RequiredError(
                'bet2',
                'Required parameter "bet2" was null or undefined when calling getBetQuote().'
            );
        }

        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling getBetQuote().'
            );
        }

        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getBetQuote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ugId'] != null) {
            queryParameters['ugId'] = requestParameters['ugId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bets/quote/{tenant}/{gameId}/{bet1}/{bet2}`.replace(`{${"bet1"}}`, encodeURIComponent(String(requestParameters['bet1']))).replace(`{${"bet2"}}`, encodeURIComponent(String(requestParameters['bet2']))).replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))).replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BetQuoteFromJSON(jsonValue));
    }

    /**
     */
    async getBetQuote(requestParameters: GetBetQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BetQuote> {
        const response = await this.getBetQuoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGameBetInfoRaw(requestParameters: GetGameBetInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GameBetInfo>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling getGameBetInfo().'
            );
        }

        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getGameBetInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bets/game-bets/{tenant}/{gameId}/info`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))).replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameBetInfoFromJSON(jsonValue));
    }

    /**
     */
    async getGameBetInfo(requestParameters: GetGameBetInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GameBetInfo> {
        const response = await this.getGameBetInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGameBetsRaw(requestParameters: GetGameBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GameBet>>> {
        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getGameBets().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bets/{trId}/game-bets`.replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GameBetFromJSON));
    }

    /**
     */
    async getGameBets(requestParameters: GetGameBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GameBet>> {
        const response = await this.getGameBetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPublicGameBetsRaw(requestParameters: GetPublicGameBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GameBet>>> {
        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getPublicGameBets().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getPublicGameBets().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bets/{trId}/public-game-bets/{userId}`.replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GameBetFromJSON));
    }

    /**
     */
    async getPublicGameBets(requestParameters: GetPublicGameBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GameBet>> {
        const response = await this.getPublicGameBetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mergeBetsRaw(requestParameters: MergeBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['destUserId'] == null) {
            throw new runtime.RequiredError(
                'destUserId',
                'Required parameter "destUserId" was null or undefined when calling mergeBets().'
            );
        }

        if (requestParameters['srcUserName'] == null) {
            throw new runtime.RequiredError(
                'srcUserName',
                'Required parameter "srcUserName" was null or undefined when calling mergeBets().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bets/merge-bets/{destUserId}/{srcUserName}`.replace(`{${"destUserId"}}`, encodeURIComponent(String(requestParameters['destUserId']))).replace(`{${"srcUserName"}}`, encodeURIComponent(String(requestParameters['srcUserName']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async mergeBets(requestParameters: MergeBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.mergeBetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveGameBetsRaw(requestParameters: SaveGameBetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GameBet>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bets/game-bets`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BetToJSON(requestParameters['bet']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameBetFromJSON(jsonValue));
    }

    /**
     */
    async saveGameBets(requestParameters: SaveGameBetsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GameBet> {
        const response = await this.saveGameBetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
