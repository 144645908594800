/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GameResult } from './GameResult';
import {
    GameResultFromJSON,
    GameResultFromJSONTyped,
    GameResultToJSON,
} from './GameResult';

/**
 * 
 * @export
 * @interface GameState
 */
export interface GameState {
    /**
     * 
     * @type {string}
     * @memberof GameState
     */
    state?: string;
    /**
     * 
     * @type {Array<GameResult>}
     * @memberof GameState
     */
    gameResults?: Array<GameResult>;
}

/**
 * Check if a given object implements the GameState interface.
 */
export function instanceOfGameState(value: object): value is GameState {
    return true;
}

export function GameStateFromJSON(json: any): GameState {
    return GameStateFromJSONTyped(json, false);
}

export function GameStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameState {
    if (json == null) {
        return json;
    }
    return {
        
        'state': json['state'] == null ? undefined : json['state'],
        'gameResults': json['gameResults'] == null ? undefined : ((json['gameResults'] as Array<any>).map(GameResultFromJSON)),
    };
}

export function GameStateToJSON(value?: GameState | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'state': value['state'],
        'gameResults': value['gameResults'] == null ? undefined : ((value['gameResults'] as Array<any>).map(GameResultToJSON)),
    };
}

