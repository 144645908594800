/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TournamentRoundGroupMember } from './TournamentRoundGroupMember';
import {
    TournamentRoundGroupMemberFromJSON,
    TournamentRoundGroupMemberFromJSONTyped,
    TournamentRoundGroupMemberToJSON,
} from './TournamentRoundGroupMember';
import type { Game } from './Game';
import {
    GameFromJSON,
    GameFromJSONTyped,
    GameToJSON,
} from './Game';
import type { TournamentRound } from './TournamentRound';
import {
    TournamentRoundFromJSON,
    TournamentRoundFromJSONTyped,
    TournamentRoundToJSON,
} from './TournamentRound';
import type { BetView } from './BetView';
import {
    BetViewFromJSON,
    BetViewFromJSONTyped,
    BetViewToJSON,
} from './BetView';
import type { BettingUser } from './BettingUser';
import {
    BettingUserFromJSON,
    BettingUserFromJSONTyped,
    BettingUserToJSON,
} from './BettingUser';
import type { UserGroup } from './UserGroup';
import {
    UserGroupFromJSON,
    UserGroupFromJSONTyped,
    UserGroupToJSON,
} from './UserGroup';

/**
 * 
 * @export
 * @interface GroupInternalHighscore
 */
export interface GroupInternalHighscore {
    /**
     * 
     * @type {UserGroup}
     * @memberof GroupInternalHighscore
     */
    userGroup: UserGroup;
    /**
     * 
     * @type {TournamentRound}
     * @memberof GroupInternalHighscore
     */
    tournamentRound: TournamentRound;
    /**
     * 
     * @type {Array<TournamentRoundGroupMember>}
     * @memberof GroupInternalHighscore
     */
    tournamentRoundGroupMembers: Array<TournamentRoundGroupMember>;
    /**
     * 
     * @type {Array<BettingUser>}
     * @memberof GroupInternalHighscore
     */
    users: Array<BettingUser>;
    /**
     * 
     * @type {Array<Game>}
     * @memberof GroupInternalHighscore
     */
    games: Array<Game>;
    /**
     * 
     * @type {Array<TournamentRound>}
     * @memberof GroupInternalHighscore
     */
    childTournamentRounds: Array<TournamentRound>;
    /**
     * 
     * @type {Array<Array<BetView>>}
     * @memberof GroupInternalHighscore
     */
    leafResults: Array<Array<BetView>>;
    /**
     * 
     * @type {Array<Array<TournamentRoundGroupMember>>}
     * @memberof GroupInternalHighscore
     */
    nodeResults: Array<Array<TournamentRoundGroupMember>>;
}

/**
 * Check if a given object implements the GroupInternalHighscore interface.
 */
export function instanceOfGroupInternalHighscore(value: object): value is GroupInternalHighscore {
    if (!('userGroup' in value) || value['userGroup'] === undefined) return false;
    if (!('tournamentRound' in value) || value['tournamentRound'] === undefined) return false;
    if (!('tournamentRoundGroupMembers' in value) || value['tournamentRoundGroupMembers'] === undefined) return false;
    if (!('users' in value) || value['users'] === undefined) return false;
    if (!('games' in value) || value['games'] === undefined) return false;
    if (!('childTournamentRounds' in value) || value['childTournamentRounds'] === undefined) return false;
    if (!('leafResults' in value) || value['leafResults'] === undefined) return false;
    if (!('nodeResults' in value) || value['nodeResults'] === undefined) return false;
    return true;
}

export function GroupInternalHighscoreFromJSON(json: any): GroupInternalHighscore {
    return GroupInternalHighscoreFromJSONTyped(json, false);
}

export function GroupInternalHighscoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupInternalHighscore {
    if (json == null) {
        return json;
    }
    return {
        
        'userGroup': UserGroupFromJSON(json['userGroup']),
        'tournamentRound': TournamentRoundFromJSON(json['tournamentRound']),
        'tournamentRoundGroupMembers': ((json['tournamentRoundGroupMembers'] as Array<any>).map(TournamentRoundGroupMemberFromJSON)),
        'users': ((json['users'] as Array<any>).map(BettingUserFromJSON)),
        'games': ((json['games'] as Array<any>).map(GameFromJSON)),
        'childTournamentRounds': ((json['childTournamentRounds'] as Array<any>).map(TournamentRoundFromJSON)),
        'leafResults': json['leafResults'],
        'nodeResults': json['nodeResults'],
    };
}

export function GroupInternalHighscoreToJSON(value?: GroupInternalHighscore | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userGroup': UserGroupToJSON(value['userGroup']),
        'tournamentRound': TournamentRoundToJSON(value['tournamentRound']),
        'tournamentRoundGroupMembers': ((value['tournamentRoundGroupMembers'] as Array<any>).map(TournamentRoundGroupMemberToJSON)),
        'users': ((value['users'] as Array<any>).map(BettingUserToJSON)),
        'games': ((value['games'] as Array<any>).map(GameToJSON)),
        'childTournamentRounds': ((value['childTournamentRounds'] as Array<any>).map(TournamentRoundToJSON)),
        'leafResults': value['leafResults'],
        'nodeResults': value['nodeResults'],
    };
}

