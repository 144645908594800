/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Highscore,
  HighscoreInfo,
  RoundWinners,
  TournamentHighscoreSlot,
} from '../models/index';
import {
    HighscoreFromJSON,
    HighscoreToJSON,
    HighscoreInfoFromJSON,
    HighscoreInfoToJSON,
    RoundWinnersFromJSON,
    RoundWinnersToJSON,
    TournamentHighscoreSlotFromJSON,
    TournamentHighscoreSlotToJSON,
} from '../models/index';

export interface GetHighscoreCountRequest {
    clientId: number;
    trId: number;
    query?: string;
}

export interface GetHighscoreForRoundAndUserRequest {
    tournamentRoundId: string;
    userId: string;
}

export interface GetHighscoreTop5Request {
    tenant: string;
    trId: string;
}

export interface GetHighscoresRequest {
    clientId: number;
    slotSize: number;
    startPos: number;
    trId: number;
    q?: string;
}

export interface GetMyTournamentHighscoreRequest {
    tId: string;
}

export interface GetWinnersRequest {
    clientId: number;
    tId: string;
}

/**
 * 
 */
export class HighscoreControllerApi extends runtime.BaseAPI {

    /**
     */
    async getHighscoreCountRaw(requestParameters: GetHighscoreCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling getHighscoreCount().'
            );
        }

        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getHighscoreCount().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['query'] != null) {
            queryParameters['query'] = requestParameters['query'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/highscore/{clientId}/{trId}/count`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))).replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getHighscoreCount(requestParameters: GetHighscoreCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getHighscoreCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getHighscoreForRoundAndUserRaw(requestParameters: GetHighscoreForRoundAndUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Highscore>> {
        if (requestParameters['tournamentRoundId'] == null) {
            throw new runtime.RequiredError(
                'tournamentRoundId',
                'Required parameter "tournamentRoundId" was null or undefined when calling getHighscoreForRoundAndUser().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getHighscoreForRoundAndUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/highscore/user/{tournamentRoundId}/{userId}`.replace(`{${"tournamentRoundId"}}`, encodeURIComponent(String(requestParameters['tournamentRoundId']))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HighscoreFromJSON(jsonValue));
    }

    /**
     */
    async getHighscoreForRoundAndUser(requestParameters: GetHighscoreForRoundAndUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Highscore> {
        const response = await this.getHighscoreForRoundAndUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getHighscoreInfosRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HighscoreInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/highscore/my`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HighscoreInfoFromJSON));
    }

    /**
     */
    async getHighscoreInfos(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HighscoreInfo>> {
        const response = await this.getHighscoreInfosRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getHighscoreTop5Raw(requestParameters: GetHighscoreTop5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Highscore>>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getHighscoreTop5().'
            );
        }

        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getHighscoreTop5().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/highscore/top5/{tenant}/{trId}`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))).replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HighscoreFromJSON));
    }

    /**
     */
    async getHighscoreTop5(requestParameters: GetHighscoreTop5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Highscore>> {
        const response = await this.getHighscoreTop5Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getHighscoresRaw(requestParameters: GetHighscoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Highscore>>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling getHighscores().'
            );
        }

        if (requestParameters['slotSize'] == null) {
            throw new runtime.RequiredError(
                'slotSize',
                'Required parameter "slotSize" was null or undefined when calling getHighscores().'
            );
        }

        if (requestParameters['startPos'] == null) {
            throw new runtime.RequiredError(
                'startPos',
                'Required parameter "startPos" was null or undefined when calling getHighscores().'
            );
        }

        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getHighscores().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/highscore/{clientId}/{trId}/{startPos}/{slotSize}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))).replace(`{${"slotSize"}}`, encodeURIComponent(String(requestParameters['slotSize']))).replace(`{${"startPos"}}`, encodeURIComponent(String(requestParameters['startPos']))).replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HighscoreFromJSON));
    }

    /**
     */
    async getHighscores(requestParameters: GetHighscoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Highscore>> {
        const response = await this.getHighscoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMyTournamentHighscoreRaw(requestParameters: GetMyTournamentHighscoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentHighscoreSlot>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getMyTournamentHighscore().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/highscore/my-tournament-highscore/{tId}`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentHighscoreSlotFromJSON(jsonValue));
    }

    /**
     */
    async getMyTournamentHighscore(requestParameters: GetMyTournamentHighscoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentHighscoreSlot> {
        const response = await this.getMyTournamentHighscoreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getWinnersRaw(requestParameters: GetWinnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoundWinners>>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling getWinners().'
            );
        }

        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getWinners().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/highscore/{clientId}/{tId}/winner`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))).replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoundWinnersFromJSON));
    }

    /**
     */
    async getWinners(requestParameters: GetWinnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoundWinners>> {
        const response = await this.getWinnersRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
