/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface SendFeedbackRequest {
    tenant: string;
    message?: string;
    sender?: string;
    subject?: string;
}

/**
 * 
 */
export class FeedbackControllerApi extends runtime.BaseAPI {

    /**
     */
    async sendFeedbackRaw(requestParameters: SendFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling sendFeedback().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['message'] != null) {
            queryParameters['message'] = requestParameters['message'];
        }

        if (requestParameters['sender'] != null) {
            queryParameters['sender'] = requestParameters['sender'];
        }

        if (requestParameters['subject'] != null) {
            queryParameters['subject'] = requestParameters['subject'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/feedback/{tenant}`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async sendFeedback(requestParameters: SendFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.sendFeedbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
