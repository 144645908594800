import { useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import React from 'react';
import { Table } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withMenu } from '../../../reactutils/withMenu';
import { withTitle } from '../../../reactutils/withTitle';
import { MenuSysAdmin } from '../../MenuSysAdmin';
import { getBettingUserApi2, getClientAdminApi, getPushNotification2Api } from '../../System/systemConfig';
import { Section } from '../../common/Section';
import { SysAdminSystemSubMenu } from '../SysAdminSubMenu';

const ClientStatsComp: React.FC = () => {
    const pushSubscriberQuery = useQuery({
        queryKey: ['pushSubscriber'],
        queryFn: () => getPushNotification2Api().getSubscriberstats(),
    });

    const userTeamQuery = useQuery({
        queryKey: ['userTeams.count'],
        queryFn: () => getBettingUserApi2().getUserTeamStatistics(),
    });

    const clientQuery = useQuery({
        queryKey: ['client'],
        queryFn: () => getClientAdminApi().getActiveClients(),
    });

    const getClients = () => clientQuery.data?.map((c) => c.name) || [];

    return (
        <Section>
            <Table>
                <thead>
                    <tr>
                        <th>Client</th>
                        {getClients().map((c) => (
                            <th key={c}>{c}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Push Subscriber</td>
                        {getClients().map((c) => (
                            <td>{pushSubscriberQuery.data?.[c ?? ''] ?? 0}</td>
                        ))}
                    </tr>
                    <tr>
                        <td>User Teams</td>
                        {getClients().map((c) => (
                            <td>{userTeamQuery.data?.[c ?? ''] ?? 0}</td>
                        ))}
                    </tr>
                </tbody>
            </Table>
        </Section>
    );
};

export const ClientStatsScreen = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withMenu(<SysAdminSystemSubMenu />),
    withTitle('Client Statistics'),
    withLogin(),
)(ClientStatsComp);
