/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.35
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GameResult
 */
export interface GameResult {
    /**
     * 
     * @type {number}
     * @memberof GameResult
     */
    result1?: number;
    /**
     * 
     * @type {number}
     * @memberof GameResult
     */
    result2?: number;
    /**
     * 
     * @type {string}
     * @memberof GameResult
     */
    type?: string;
    /**
     * 
     * @type {Date}
     * @memberof GameResult
     */
    lastUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof GameResult
     */
    source?: string;
}

/**
 * Check if a given object implements the GameResult interface.
 */
export function instanceOfGameResult(value: object): value is GameResult {
    return true;
}

export function GameResultFromJSON(json: any): GameResult {
    return GameResultFromJSONTyped(json, false);
}

export function GameResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameResult {
    if (json == null) {
        return json;
    }
    return {
        
        'result1': json['result1'] == null ? undefined : json['result1'],
        'result2': json['result2'] == null ? undefined : json['result2'],
        'type': json['type'] == null ? undefined : json['type'],
        'lastUpdated': json['lastUpdated'] == null ? undefined : (new Date(json['lastUpdated'])),
        'source': json['source'] == null ? undefined : json['source'],
    };
}

export function GameResultToJSON(value?: GameResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'result1': value['result1'],
        'result2': value['result2'],
        'type': value['type'],
        'lastUpdated': value['lastUpdated'] == null ? undefined : ((value['lastUpdated']).toISOString()),
        'source': value['source'],
    };
}

